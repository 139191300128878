import DataLoader from "dataloader";

import { type Group, type AppUrlId, type User, type UserId, type GroupId } from "../types/entity.js";

export const appUsersLoader = new DataLoader(async (ids: readonly AppUrlId[]) => {
	const appsUsers = await Promise.all(
		ids.map(async (appId): Promise<(User & { appId: AppUrlId })[]> => {
			const users = await fetch(`/api/users?appId=${appId}`).then((res) => res.json());

			return users.users.map((user: User) => ({ ...user, appId }));
		}),
	);

	appsUsers.forEach((appUsers) => {
		appUsers.forEach((user) => {
			appUserLoader.prime({ appId: user.appId, userId: user.id }, user);
		});
	});

	return ids.map((appId) => appsUsers.find((appUsers) => appUsers.some((user) => user.appId === appId)) ?? []);
});

export const userLoader = new DataLoader(async (ids: readonly UserId[]) => {
	const users = await fetch(`/api/users?userIds=${ids.join(",")}`).then(
		(res): Promise<{ users: User[] }> => res.json(),
	);

	return ids.map((userId) => users.users.find((user) => user.id === userId));
});

export const appUserLoader = new DataLoader(
	async (ids: readonly { appId: AppUrlId; userId: UserId }[]) => {
		const appIds = [...new Set(ids.map(({ appId }) => appId))];
		const users = (await Promise.all(appIds.map(async (appId) => appUsersLoader.load(appId)))).flat();

		return ids.map(({ userId, appId }) => users.find((user) => user.id === userId && user.appId === appId));
	},
	{
		cacheKeyFn(key) {
			return `${key.appId}:${key.userId}`;
		},
	},
);

export const appGroupsLoader = new DataLoader(async (ids: readonly AppUrlId[]) => {
	return Promise.all(
		ids.map(async (appId) => {
			const response = await fetch(`/api/groups?appId=${appId}`).then(
				(res) => res.json() as Promise<{ groups: Group[] }>,
			);

			const groups = response.groups.map((group) => ({ ...group, appId }));

			groups.forEach((group) => {
				appGroupLoader.prime({ appId: group.appId, groupId: group.id }, group);
			});

			return groups;
		}),
	);
});

export const appGroupLoader = new DataLoader(
	async (ids: readonly { appId: AppUrlId; groupId: GroupId }[]) => {
		const appIds = [...new Set(ids.map(({ appId }) => appId))];
		const groups = (await Promise.all(appIds.map(async (appId) => appGroupsLoader.load(appId)))).flat();

		return ids.map(({ groupId, appId }) => groups.find((group) => group.id === groupId && group.appId === appId));
	},
	{
		cacheKeyFn(key) {
			return `${key.appId}:${key.groupId}`;
		},
	},
);
